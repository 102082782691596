import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations'
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import React from 'react'
import { useEffect, useState, useRef } from 'react';
import { Button, Breadcrumbs, Link, Card, CardContent, Box } from '@mui/material';
import { useRouter } from '@fuse/hooks';
import axios from '@fuse/utils/axios';
import NoResultView from '@fuse/components/NoResultView'
import FuseLoading from "@fuse/core/FuseLoading";
import { useCurrentUser } from '@fuse/hooks'

function Note({ setOpenAddNote, setEditNoteData, status, serviceRequestId }) {
  const gridRef = useRef();
  const router = useRouter()
  const currentUser = useCurrentUser()
  const requestId = router.params.id ?? serviceRequestId;
  const [noteList, setNoteList] = useState([])
  const [loadingState, setLoadingState] = useState(false)
  const header = [
		{
		  field: "notes",
		  headerName: "Notes",
		  minWidth: 350,
		  headerComponentFramework: CustomFilter		
		},
		{
		  field: "created_at",
		  headerName: "Created Date",
		  headerComponentFramework: CustomFilter		
		},
		{
		  field: "agent_name",
		  headerName: "Agent Name",
		  headerComponentFramework: CustomFilter	
		},
    {
		  field: "updated_at",
		  headerName: "Updated Date",
		  headerComponentFramework: CustomFilter	
		},
		{
		  field: "actions",
		  headerName: "Actions",
		  minWidth: 100,
		  maxWidth: 100,
		  headerComponentFramework: CustomFilter		
		}
	]
  const [tableHeaders, setTableHeaders] = useState(header)
  // useEffect(()=>{
  //   setNoteList(notes.map(item=>({...item,created_at:dateReformatTo_mmm_dd_yyyy(item.created_at), 
  //     updated_at: dateReformatTo_mmm_dd_yyyy(item.updated_at)})))
  // },[notes])

  const fetchNotes = () => {
    setLoadingState(true)
    axios.get(`/service/list-notes/${requestId}`).then((res)=>{
      setNoteList(res.data.data.response.map(item=>({...item,created_at:dateReformatTo_mmm_dd_yyyy(item.created_at), 
        updated_at: dateReformatTo_mmm_dd_yyyy(item.updated_at)})))  
      setLoadingState(false)
    }).catch((err)=>{
      setLoadingState(false)
      console.log(err)
    })
  }

  useEffect(()=>{
    fetchNotes()
  },[])

  useEffect(()=>{
    if(status == 'Closed') setTableHeaders(tableHeaders.slice(0,-1))
  },[status])

  if(loadingState){
    return (
      <FuseLoading/>
    )
  }

  if(!noteList.length){
    return (
      <NoResultView moduleName={'Note'}/>
    )
  }

  return (
    <>
    <div 
          className="ag-theme-alpine" 
          style={{
          width: "100%",
          height: 400,
          fontSize:'12px'
          }}
      >
          <AgGridReact
              ref={gridRef}
              rowData={noteList}
              animateRows
              // onGridReady = { onGridReady }
              paginationPageSize={10}
              pagination
              defaultColDef={{
                resizable: true
              }}
          >
              {tableHeaders.map(header => {
                  if( header.field === "notes"){
                      return(
                          <AgGridColumn 
                              key = {header.field} 
                              field = {header.field}
                              headerName = {header.headerName}  
                              width = { header.width }
                              minWidth = { header.minWidth }
                              maxWidth = { header.maxWidth }
                              sortable = {false}
                              flex = {1}
                              filter = "text"
                              wrapText
                              autoHeight
                              cellRendererFramework={(data) => {
                                return (
                                  <span
                                      style={{
                                          wordBreak: 'break-word'
                                      }}
                                  >{data.value}</span>                                )			
                              }}
                              // floatingFilter = {true}
                              headerComponentFramework = {header.headerComponentFramework}								
                          />
                      )
                  }
                  if( header.field === "agent_name"){
                    return(
                        <AgGridColumn 
                            key = {header.field} 
                            field = {header.field}
                            headerName = {header.headerName}  
                            width = { header.width }
                            minWidth = { header.minWidth }
                            maxWidth = { header.maxWidth }
                            sortable = {false}
                            flex = {1}
                            filter = "text"
                            wrapText
                            autoHeight
                            // floatingFilter = {true}
                            headerComponentFramework = {header.headerComponentFramework}								
                        />
                    )
                }
                  if( header.field === "created_at"){
                      return(
                          <AgGridColumn 
                              key = {header.field} 
                              field = {header.field}
                              headerName = {header.headerName}  
                              width = { header.width }
                              minWidth = { header.minWidth }
                              maxWidth = { header.maxWidth }
                              sortable = {false}
                              flex = {1}
                              filter = "text"
                              wrapText
                              autoHeight
                              headerComponentFramework = {header.headerComponentFramework}								
                          />
                      )
                  }if(header.field === "updated_at"){
                      return(
                          <AgGridColumn 
                              key = {header.field} 
                              field = {header.field}
                              headerName = {header.headerName}  
                              width = { header.width }
                              minWidth = { header.minWidth }
                              maxWidth = { header.maxWidth }
                              sortable = {false}
                              flex = {1}
                              filter = "text"
                              wrapText
                              autoHeight
                              headerComponentFramework = {header.headerComponentFramework}								
                          />
                      )
                  }if(header.field === "actions"){
                      return(
                          <AgGridColumn 
                              key = {header.field} 
                              field = {header.field}
                              headerName = {header.headerName}  
                              width = { header.width }
                              minWidth = { header.minWidth }
                              maxWidth = { header.maxWidth }
                              sortable = {false}
                              flex = {1}
                              filter = "text"
                              wrapText
                              autoHeight
                              cellRendererFramework={(data) => {
                                  return (
                                    (data.data.user_companies_users_id == currentUser.data.id) ? <Button disabled={status == 'Closed'} onClick={()=>{
                                        setOpenAddNote(true)
                                        setEditNoteData(data)
                                      }} className="transparent_icon_btn text-center">
                                          <i style={{fontSize: '20px', fontWeight: '500', color: '#1D2939'}} className="ti ti-pencil" />
                                      </Button>:<></>
                                  );				
                              }}							
                          />
                      )
                  }						
              })}
          </AgGridReact>
      </div>
    </>
  )
}

export default Note