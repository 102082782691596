import React, {useState,useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import "ag-grid-enterprise";
import "styles/ag-grid-header-style.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';
import axios from "@fuse/utils/axios";
import useRouter from "@fuse/hooks/useRouter";

const useStyles = makeStyles({
    root: {
        '& .MuiDataGrid-columnsContainer': {
            background: '#8f8f8f26'
        }
    }
});

const action_to_color = {
    "CREATED": "green",
    "UPDATED": "orange",
    "ARCHIVED": "red",
    "RESTORED": "blue",
    'DELETED': "red"
}

function ActivityAgGridListing({ module, displayTableName='' }) {
    const [windowHeight, setWindowHeight] = useState(window.innerHeight - 330);
    const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
    const [groupByArr, setGroupByArr] = useState([])
    const groupByModule = ['asset', 'Contract', 'service_request', 'subscription', 'Manufacture', 'Model', 
                            'Accessory', 'service_provider', 'subscription_provider', 'user', 'shipping', 'asset activity','asset_category',
                            'custom_attributes','asset_status', 'accessory_category','payment_term','contract_tag','Contract Types',
                            'service_level_agreement','location_type','department'
                        ]
    const gridRef = useRef();
    const router = useRouter();

    const headers = [
        'action_id',
        'serial_number',
        'action_date_and_time',
        'action',
        'field_name',
        'previous_value',
        'current_value',
        'name',
        'user_role',
        'description',
        'company_name'
    ]

    function cellRendererFramework (params) {
        if(params.colDef.field === "action"){
            return (
                <span style={{
                    color: action_to_color[params.value],
                    fontWeight: 'bold'
                }}>{params.value}</span>
            )
        }
        else if(params.colDef.field === "description" || params.colDef.field === 'previous_value' || params.colDef.field === 'current_value'){
            if(module === 'asset'){
                let desc = ''
                if (typeof params.value !== 'object' || !params.value) {
                    let date = params?.value?.split('{')[1]?.slice(0,-1)
                    if(date){
                        let dateFormat = Intl.DateTimeFormat('en-US', {
                            month: 'numeric',
                            day: 'numeric',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            hour12: true
                        }).format(new Date(date));
                        
                        desc = `${params.value.split('{')[0]} ` + dateFormat
                    }else{
                        desc = params.value
                    }
                    return (
                        <abbr style={{textDecoration: 'none', wordBreak: 'break-word'}} title={desc}>{desc}</abbr>
                    )
                }else{
                    desc =(params.value?.word &&  params.value?.number) ? "\u202A" + params.value?.word + ' ' + "\u202C"  + params.value?.number : 'N/A'

                    return (
                        <abbr style={{textDecoration: 'none', wordBreak: 'break-word'}} title={desc}>{desc}</abbr>
                    )
                }
            }else{
                return (
                    <abbr style={{textDecoration: 'none', wordBreak: 'break-word'}} title={params.value}>{params.value}</abbr>
                )
            }
        } else{
            return <>{params.value}</>
        }
    }

    const generateHeaderLabel = (header) => {
        if(header === 'action_date_and_time') return 'Action date and time'
        return header
          .split("_")
          .map((word) => {
            return word.charAt(0).toUpperCase() + word.substring(1);
          })
          .join(" ");
    };

    const frameworkComponents = {
		customFilter: CustomFilter,
	};

    function onColumnRowGroupChanged(event){
		if(event.columns.length == 0) {
			setGroupByArr([])
		}else{
			setGroupByArr(event.columns)
		}
	}


    function onFilterChanged(event){		
		const rows = event.api.getFilterModel();
		const total_rows = event.api.getDisplayedRowCount();
		if(total_rows === 0 ){
			event.api.showNoRowsOverlay();
		}else{
			event.api.hideOverlay();
		}
	}

    async function onFetchAsset(params){
        let url = '/assets/get-all-activities' ;
        const {startRow, endRow, filterModel, sortModel, rowGroupCols, groupKeys, pivotMode} = params.request;
        axios.post(url,{
          dataSourceParams: {
            startRow, endRow, filterModel, sortModel, rowGroupCols, groupKeys, pivotMode
          }
        }).then((res)=>{    
          params.successCallback(formatData(res.data.data.rows), res.data.data.lastRow);
        }).catch((err) => {
          console.log('error', err)
          params.failCallback();
        })
    }

    function formatData(data){
		return data.map((item) => {
			return {
				id:item.asset_logs_id,
				action_id: item.action_id,
				serial_number: item?.serial_number ?? 'N/A',
				action_date_and_time: item.action_date_and_time,
				name: item.user_name,
				action: item.action_name,
				description: item.comment,
				previous_value:  item.previous_data,
                current_value:  item.current_data,
				user_role: item.user_role_name,
				field_name: item.action_field_name,
                company_name: item?.company_name,
			}
		})
	}

      /** This object represents the data source for the grid.
  *It defines a `getRows` function that is invoked by the grid to fetch rows from the server.
  */
  const datasource = {
    getRows(params) {
      // Call the function to fetch asset data from the server.
      onFetchAsset(params)
    }
  };

  const onGridReady = (params) => {
    // setAggridParam(params)

    params.api.sizeColumnsToFit()
    // setGridApi(params)
    if (router.query.report_name == undefined) {
        params?.api.setServerSideDatasource(datasource);
      }
  };

    return (
        <>
        {groupByModule.includes(module) && <AgGridFilterGroupView tableRef={gridRef} setRowGroupPanelShow={setRowGroupPanelShow}
            groupByArrFromList ={ groupByArr }
            tableName= {module == 'asset'? 'asset activity': module} activityModuleName ={module} displayTableName ={displayTableName} forActivity = {true}
        />
        }
        <div
            className="ag-theme-alpine"
            style={{
                width: "100%",
                height: windowHeight + 90,
                fontSize: "12px",
                display: ''
            }}
        >
            <AgGridReact
            ref = { gridRef}
            onGridReady = { onGridReady }
            animateRows = { true }
            //rowData = {props.endUsersAccounts}
            rowHeight = {30}
            rowModelType='serverSide'
            serverSideStoreType="partial"
            defaultColDef={{
                enableRowGroup:true,
            sortable: true,
            resizable: true
            }}
            defaultExcelExportParams={{
            sheetName: 'Dalos-Source'
            }}
            pagination
            overlayNoRowsTemplate={'<span>No Result Found</span>'}

            // Row Grouping conditions
            showOpenedGroup={true}
            suppressDragLeaveHidesColumns={true}
            suppressMakeColumnVisibleAfterUnGroup={true}
            rowGroupPanelShow={ rowGroupPanelShow }
            groupHeaderHeight ={1}
            frameworkComponents={frameworkComponents}
            floatingFiltersHeight = {40}
            // onSortChanged={onSortChanged}
            // get filter model
            onFilterChanged = { onFilterChanged }	
            
            // on column group changed
            onColumnRowGroupChanged = { onColumnRowGroupChanged }
            >
                {headers.map((header) => {
                    if(header == 'action_date_and_time'){
                        return (
                            <AgGridColumn
                                field={header}
                                key={header}
                                suppressSizeToFit={true}
                                // suppressRowClickSelection={true}
                                headerName={generateHeaderLabel(header)}
                                filter="text"
                                minWidth={250}
                                sortable={true}
                                cellRendererFramework={cellRendererFramework}
                                // comparator={customSort}
                                headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                            ></AgGridColumn>
                        );
                    } else if(header == 'description'){
                        return (
                            <AgGridColumn
                                field={header}
                                key={header}
                                suppressSizeToFit={true}
                                // suppressRowClickSelection={true}
                                headerName={generateHeaderLabel(header)}
                                filter="text"
                                wrapText
                                autoHeight
                                sortable={true}
                                cellRendererFramework={cellRendererFramework}
                                // comparator={customSort}
                                headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                            ></AgGridColumn>
                        );
                    }
                    return (
                        <AgGridColumn
                            field={header}
                            key={header}
                            suppressSizeToFit={true}
                            suppressRowClickSelection={true}
                            headerName={generateHeaderLabel(header)}
                            filter="text"
                            minWidth={(header == 'service_provider_name' || header == 'service_level_agreement_name') ? 300: 200}
                            sortable={true}
                            wrapText
                            // autoHeight = {header.field === 'description'}
                            cellRendererFramework={cellRendererFramework}
                            
                            headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                        ></AgGridColumn>
                    );
                })}
            </AgGridReact>
        </div>
        </>
    )
}

export default ActivityAgGridListing;