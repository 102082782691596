const assets_source = {
    csv: 'CSV',
    manual: 'Manual',
    juniper: 'Juniper'
}

const assets_network_status = {
    unknown: 'Unknown',
    connected: 'Connected',
    disconnected: 'Disconnected'
}

const contract_status = {
    expired: 'Expired',
    expiring: 'Expiring',
    active: 'Active'
}

const contract_master = {
    yes: 'Yes',
    no: 'No',
}

const source = {
    csv: 'CSV',
    manual: 'Manual',
}

const actions = {
    update: 'Updated',
    create: 'Created',
    delete: 'Deleted',
    restored: 'Restored',
    archived: 'Archived',
}

const asset_notification_status = {
    'Muted': 'Muted',
    'Unmuted': 'Unmuted'
}

module.exports = {
    assets_source, 
    assets_network_status,
    contract_status, 
    contract_master, 
    source,
    actions,
    asset_notification_status
}