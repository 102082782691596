import React, { useEffect, useState,useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import "ag-grid-enterprise";
import "styles/ag-grid-header-style.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';
import FuseLoading from '@fuse/core/FuseLoading';
// import customSort from '@fuse/utils/aggridCustomSort';
import { setGroupViewTabValue } from '../../../app/main/reports/store/reportViewDialogReducer';
import { useDispatch } from "react-redux";
import { countries } from '@fuse/utils/countryName'


const useStyles = makeStyles({
    root: {
        '& .MuiDataGrid-columnsContainer': {
            background: '#8f8f8f26'
        }
    }
});

const action_to_color = {
    "CREATED": "green",
    "UPDATED": "orange",
    "ARCHIVED": "red",
    "RESTORED": "blue",
    'DELETED': "red"
}

function ActivityAgGridListingSameAsSam({ module, logs=[], displayTableName='', loadingActivityLog }) {
    const classes = useStyles();
    const [activities, setActivities] = useState([])
    const [windowHeight, setWindowHeight] = useState(window.innerHeight - 330);
    const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
    const [groupByArr, setGroupByArr] = useState([])
    const groupByModule = ['asset', 'Contract', 'service_request', 'subscription', 'Manufacture', 'Model', 
                            'Accessory', 'service_provider', 'subscription_provider', 'user', 'shipping', 'asset activity','asset_category',
                            'custom_attributes','asset_status', 'accessory_category','payment_term','contract_tag','Contract Types',
                            'service_level_agreement','location_type','department','service_request_category','service_request_status',
                            'service_request_source','service_ticket_priority','agents','customer_management'
                        ]
    const dispatch = useDispatch();
    const gridRef = useRef();
    const headers = [
        'action_id',
        'serial_number',
        'action_date_and_time',
        'action',
        'field_name',
        'previous_value',
        'current_value',
        'name',
        'user_role',
        'description',
        'company_name'
    ]

    // updating the header for different module
    if(module === 'asset') headers[1] = 'serial_number'
    else if(module === 'Contract') headers[1] = 'contract_number'
    else if(module === 'subscription') headers[1] = 'subscription_name'
    else if(module === 'Manufacture') headers[1] = 'manufacturer_name'
    else if(module === 'asset_category') headers[1] = 'category_name'
    else if(module === 'Model') headers[1] = 'model_name'
    else if(module === 'Accessory') headers[1] = 'accessory_name'
    else if(module === 'custom_attributes') headers[1] = 'attribute_name'
    else if(module === 'asset_status') headers[1] = 'status_name'
    else if(module === 'accessory_category') headers[1] = 'category_name'
    else if(module === 'subscription_category') headers[1] = 'category_name'
    else if(module === 'subscription_status') headers[1] = 'status_name'
    else if(module === 'payment_term') headers[1] = 'payment_term_name'
    else if(module === 'contract_status') headers[1] = 'status_name'
    else if(module === 'Contract Types') headers[1] = 'contract_type_name'
    else if(module === 'service_provider') headers[1] = 'service_provider_name'
    else if(module === 'service_level_agreement') headers[1] = 'service_level_agreement_name'
    else if(module === 'service_request'){
        headers[1] = 'ticket_id'
        headers.push('asset_serial_number')
    } 
    else if(module === 'service_request_category') headers[1] = 'category_name'
    else if(module === 'service_request_status') headers[1] = 'status_name'
    else if(module === 'service_request_source') headers[1] = 'source_name'
    else if(module === 'role') headers[1] = 'role_name'
    else if(module === 'shipping') headers[1] = 'location_name'
    else if(module === 'department') headers[1] = 'department_name'
    else if(module === 'user') headers[1] = 'user_name'
    else if(module === 'location_type') headers[1] = 'location_type_name'
    else if(module === 'subscription_provider') headers[1] = 'provider_name'
    else if(module === 'customer_management') headers[1] = 'email'
    else headers.splice(1, 1)

    function cellRendererFramework (params) {
        if(params.colDef.field === "action"){
            return (
                <span style={{
                    color: action_to_color[params.value],
                    fontWeight: 'bold'
                }}>{params.value}</span>
            )
        }/*else if(params.colDef.headerName === "Group" && params.node.field === "action") {
            // console.log('54: ',params);
            return (
                <span style={{
                    color: action_to_color[params.value],
                    fontWeight: 'bold'
                }}>{params.value}</span>
            )
        }*/
        else if(params.colDef.field === 'previous_value' || params.colDef.field === 'current_value'){
            if(module === 'asset'){
                let desc = ''
                let date = params?.value?.split('{')[1]?.slice(0,-1)
                if(date){
                    let dateFormat = Intl.DateTimeFormat('en-US', {
                        month: 'numeric',
                        day: 'numeric',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        hour12: true
                    }).format(new Date(date));
                    
                    desc = `${params.value.split('{')[0]} ` + dateFormat
                }else{
                    desc = params.value
                }
                return (
                    <abbr style={{textDecoration: 'none'}} title={desc}>{desc}</abbr>
                )
            }else if(module === 'Contract' && params?.data?.field_name === "Currency" ){
                const getCountryDetails = (countries.filter(country => country.currency === params.value))[0]
                if(params.colDef.field === 'previous_value' || params.colDef.field === 'current_value'){
                return (
                    <div className='flex items-center'>
                        <img
                            loading="lazy"
                            // width={"20px"}
                            className='mr-5 py-2'
                            src={`https://flagcdn.com/w20/${getCountryDetails.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${getCountryDetails.code.toLowerCase()}.png 2x`}
                            alt=""
                        />
                        <span className='pt-2'>{params.value}</span>
                    </div>
                )
                }else{
                    return <>{params.value}</>
                }
            }else{
                return (
                    <abbr style={{textDecoration: 'none'}} title={params.value}>{params.value}</abbr>
                )
            }
        }else if( params.colDef.field === 'description'){
            return(
                <span
                    style={{
                        wordBreak: 'break-word'
                    }}
                >{params.value}</span>
            )
        }
        else{
            return <>{params.value}</>
        }
    }

    const generateHeaderLabel = (header) => {
        if(header === 'action_date_and_time') return 'Action date and time'
        return header
          .split("_")
          .map((word) => {
            return word.charAt(0).toUpperCase() + word.substring(1);
          })
          .join(" ");
    };

    const frameworkComponents = {
		customFilter: CustomFilter,
	};

    useEffect(() => {
        if(logs.length){
            setActivities(logs)
            gridRef?.current?.api.hideOverlay();
        }else{
            gridRef.current?.api.showNoRowsOverlay();
        }
    }, [logs])

    function onColumnRowGroupChanged(event){
		if(event.columns.length == 0) {
			setGroupByArr([])
		}else{
			setGroupByArr(event.columns)
		}
	}

    useEffect(()=>{
        return () => {
            dispatch(setGroupViewTabValue(0))
        }        
    }, [])

    return (
        <>
        { groupByModule.includes(module) && <AgGridFilterGroupView tableRef={gridRef} setRowGroupPanelShow={setRowGroupPanelShow}
            groupByArrFromList ={ groupByArr }
            tableName= {module == 'asset'? 'asset activity': module} logs={logs} displayTableName = {displayTableName} forActivity = {true}
        />
        }
        {loadingActivityLog && <FuseLoading/>}
        {!loadingActivityLog && <div
            className="ag-theme-alpine"
            style={{
                width: "100%",
                height: windowHeight + 90,
                fontSize: "12px",
            }}
        >
            <AgGridReact
                ref={gridRef}
                animateRows={true}
                rowData={activities}
                rowHeight={28}
                suppressRowClickSelection
                suppressDragLeaveHidesColumns
                suppressMakeColumnVisibleAfterUnGroup
                overlayLoadingTemplate={"<span>Loading...</span>"}
                overlayNoRowsTemplate={"<span>No Result Found</span>"}
                showOpenedGroup={true}
                rowGroupPanelShow={rowGroupPanelShow}
                groupHeaderHeight={1}
                pagination={true}
                defaultColDef={{
                    enableRowGroup:true,
                    resizable: true
                }}
                frameworkComponents={frameworkComponents}
                onColumnRowGroupChanged = { onColumnRowGroupChanged }
            >
                {headers.map((header) => {
                    if(header == 'action_date_and_time'){
                        return (
                            <AgGridColumn
                                field={header}
                                key={header}
                                suppressSizeToFit={true}
                                // suppressRowClickSelection={true}
                                headerName={generateHeaderLabel(header)}
                                filter="text"
                                minWidth={250}
                                sortable={true}
                                cellRendererFramework={cellRendererFramework}
                                // comparator={customSort}
                                headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                            ></AgGridColumn>
                        );
                    } else if(header == 'description'){
                        return (
                            <AgGridColumn
                                field={header}
                                key={header}
                                suppressSizeToFit={true}
                                // suppressRowClickSelection={true}
                                headerName={generateHeaderLabel(header)}
                                filter="text"
                                wrapText
                                autoHeight
                                sortable={true}
                                cellRendererFramework={cellRendererFramework}
                                // comparator={customSort}
                                headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                            ></AgGridColumn>
                        );
                    }
                    return (
                        <AgGridColumn
                            field={header}
                            key={header}
                            suppressSizeToFit={true}
                            suppressRowClickSelection={true}
                            headerName={generateHeaderLabel(header)}
                            filter="text"
                            minWidth={(header == 'service_provider_name' || header == 'service_level_agreement_name') ? 300: 200}
                            sortable={true}
                            cellRendererFramework={cellRendererFramework}
                            
                            headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                        ></AgGridColumn>
                    );
                })}
            </AgGridReact>
        </div>}
        </>
    )
}

export default ActivityAgGridListingSameAsSam;