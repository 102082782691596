import React, { useEffect, useState, useRef } from 'react'

import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import FuseLoading from '@fuse/core/FuseLoading/FuseLoading';
import { withRouter } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';

import axios from "@fuse/utils/axios";
import { duration } from 'moment';
import Status from '../Status';
import { getLocalTimeFromUTC } from '@fuse/utils/dateOperations';

const useStyles = makeStyles({

    root: {
        // '& .MuiDataGrid-root-1911 .MuiDataGrid-footer':{
        //   border: '2px solid black',
        //   display: 'none !important'
        // }
        '& .MuiDataGrid-columnsContainer': {
            background: '#8f8f8f26'
        }
    }
});


function IncidentsTab(props) {
    const classes = useStyles();
    const tableHeaders = [
        {
          field: "severity",
          headerName: "Severity",
          headerComponentFramework: CustomFilter,		
          minWidth: 150
        },
        {
          field: "time",
          headerName: "Event Date & Time",
          minWidth: 260,
          headerComponentFramework: CustomFilter		
        },
        {
          field: "problem",
          headerName: "Description",
          minWidth: 350,
          maxWidth: 350,
          headerComponentFramework: CustomFilter	
        },
        {
          field: "recovery_time",
          headerName: "Recovery Time",
          minWidth: 150,
          headerComponentFramework: CustomFilter	
        },
        {
          field: "status",
          headerName: "Status",
          minWidth: 150,
          headerComponentFramework: CustomFilter	
        },
        {
          field: "duration",
          headerName: "Duration",
          // cellRendererFramework: AssetActionComponent,
          minWidth: 140,
          maxWidth: 140
        }
    ];
    
    const gridRef = useRef()
    
    const onGridReady = params => {
        // Following line to make the currently visible columns fit the screen  
        params.api.sizeColumnsToFit();
     
        // Following line dymanic set height to row on content
        params.api.resetRowHeights();
    };
    const [rowData, setRowData] = useState([]);
	const [windowHeight, setWindowHeight ]  = useState(window.innerHeight - 430);

    const [loading, setLoading] = useState(false)

    const { assetDetails } = props

    function formtIncidents(incidents) {
        return incidents.map((item) => {
            return {
                id: item.id,
                // ticket_number: '27927',
                severity: item.event_severity,
                time: getLocalTimeFromUTC(item.created_at),
                recovery_time: item.recovery_time,
                status: item.event_status,
                problem: item.alert_subject,
                duration: item.event_duration
            }
        })
    }
    function getIncidents() {
        if (!assetDetails) return
        setLoading(true)
        axios.get(`/assets/fetch-asset-incident/${assetDetails.id}`, {
        }).then((res) => {
            const { incident } = res.data.data;
            setRowData(formtIncidents(incident))
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        getIncidents()
    }, [])

    if (loading) {
        return (
            <FuseLoading />
        )
    }

    return (
            <>
            {rowData.length === 0 && 
                <div className='no_data_holder'>
                    <img src='assets/images/nodata.svg' alt='icon' />
                    <p className='bold_txt'>It's all empty here!</p>
                    <p className='normal_txt'>No data to show yet</p>
                </div>
            }
            
            {
            rowData.length > 0 &&  
                <div 
                    className="ag-theme-alpine" 
                    style={{
                    width: "100%",
                    height: windowHeight+105,
                    fontSize:'12px'
                    }}
                >
                    <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        animateRows
                        onGridReady = { onGridReady }
                        paginationPageSize={10}
                        pagination
                        defaultColDef={{
                            resizable: true
                        }}
                    >
                    {tableHeaders.map(header => {
                        if( header.field === "problem"){
                            return(
                            <AgGridColumn 
                                key = {header.field} 
                                field = {header.field}
                                headerName = {header.headerName}  
                                width = { header.width }
                                minWidth = { header.minWidth }
                                maxWidth = { header.maxWidth }
                                sortable = {false}
                                flex = {1}
                                filter = "text"
                                // cellRendererFramework={(event) => (<ParametersComponent data={event.data}/>)}
                                wrapText
                                autoHeight
                                // floatingFilter = {true}
                                headerComponentFramework = {header.headerComponentFramework}								
                            />
                            )
                        }if( header.field === "status"){
                            return(
                            <AgGridColumn 
                                key = {header.field} 
                                field = {header.field}
                                headerName = {header.headerName}  
                                width = { header.width }
                                minWidth = { header.minWidth }
                                maxWidth = { header.maxWidth }
                                sortable = {false}
                                flex = {1}
                                filter = "text"
                                cellRendererFramework={(event) => {
                                    return (
                                        <span
                                            style={{
                                                backgroundColor: event.value.toLowerCase() === 'resolved' ? '#f2ffeb' : '#FFFAEB',
                                                color: event.value.toLowerCase() === 'resolved' ? '#4bf709' : '#F79009',
                                                fontSize: '12px',
                                                fontWeight: '500',
                                                border: `1px solid ${event.value.toLowerCase() === 'resolved' ? '#4bfe59' : '#FEC84B'}`,
                                                borderRadius: '16px',
                                                padding: '2px 16px'
                                            }}
                                        >
                                            {event.value.charAt(0).toUpperCase() + event.value.slice(1).toLowerCase()}
                                        </span>                                            
                                    );
                                }}
                                wrapText
                                headerComponentFramework = {header.headerComponentFramework}								
                            />
                            )
                        }
                            return(
                            <AgGridColumn 
                                key = {header.field} 
                                field = {header.field}
                                headerName = {header.headerName}  
                                width = { header.width }
                                minWidth = { header.minWidth }
                                maxWidth = { header.maxWidth }
                                sortable = {false}
                                flex = {1}
                                filter = "text"
                                wrapText
                                headerComponentFramework = {header.headerComponentFramework}								
                            />
                            )
                        
                    })}
                    </AgGridReact>
                </div>
            }
            </>
        )

    // return (
    //     <>
    //         <div className={classes.root} style={{ height: 290, width: '100%', overflow: "auto" }}>
    //             <DataGrid
    //                 rows={incidents}
    //                 columns={columns}
    //                 hideFooter
    //             />
    //         </div>
    //     </>
    // )
}

export default withRouter(IncidentsTab)
